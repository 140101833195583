import React from 'react'

const CancellationPolicy = () => {
  return (
    <div>
      <div style={{ minHeight: "81vh" }}>
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // padding: "10px",
            gap: "10px",
            margin: "10px",
            // maxWidth:'1500px',
            padding: "40px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
           <h2>Cancellation Policy</h2>
      <ul style={{alignSelf:'flex-start',listStyleType:'disc', lineHeight:'2'}}>
        <li>Any cancelled tickets amount will be credited in your account within 5 to 7 bank working days.</li>
        <li>Cancellation Ticket Terms Depend On Operator Cancellation Policy.</li>
        <li><span style={{color:'red', fontWeight:'bold'}}>Note: </span> Some bus operators do not allow cancellations and hence no refund is possible in such cases. Some bus operators charge higher cancellation penalties while some allow cancellation up to 4 hours before departure. Please contact any of our support care for cancellation details on any specific service.</li>
        <li><span style={{color:'red', fontWeight:'bold'}}>Non-Transferable: </span>
          <ul>
            <li>Once a bus ticket is issued, it is non-transferable. If a ticket is presented by someone other than the person entitled to be carried there-under or to refund in connection therewith, <span>Sri Vinayaka Travels</span> or any of its travel partners shall not be liable to the person so entitled, if in good faith it provides carriage or makes a refund to the person presenting the ticket.</li>
          </ul>
        </li>
        <li>In case the bus operator changes the type of bus due to some reason, <span>Sri Vinayaka Travels</span> will not be responsible.</li>
        <li>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
      </ul>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  )
}

export default CancellationPolicy