import React, { useState } from "react";
import {
  AppBar,
  Button,
  // Tab,
  // Tabs,
  Toolbar,
  // Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo2 from "../assets/logo2.png";
import ClearIcon from "@mui/icons-material/Clear";
import HistoryIcon from '@mui/icons-material/History';

// import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import DrawerComp from "./DrawerComp";
import { NavLink, useNavigate } from "react-router-dom";
import "./Header.css";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { Modal } from "react-bootstrap";
import Login from "./Login";
import Signup from "./Signup";

const Header = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let navigate = useNavigate();
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handleLoginModal=()=>{
    setLoginModal(false)
    setIsAuthenticated(true)
  }
  // console.log(isMatch);

  const handleSignUp=()=>{
    setLoginModal(false)
    setSignupModal(true);
  }

  return (
    <React.Fragment>
      <AppBar style={{ background: "#063970" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              navigate("tour-packages");
              window.location.reload();
            }}
          >
            {/* <DirectionsBusFilledIcon sx={{ transform: "scale(2)" , marginRight:"10px"}} /> */}
            <img
              src={logo2}
              alt=""
              style={{
                height: "35px",
                width: "70px",
                marginRight: "10px",
                borderRadius: "5px",
              }}
            />
            <NavLink className="nav__logo">Sri Vinayaka Tours</NavLink>
            {/* <span>Sri Vinayaka Travels</span> */}
          </div>
          {isMatch ? (
            <>
              {/* <Typography sx={{ fontSize: "2rem", paddingLeft: "10%" }}>
                <DrawerComp />
              </Typography> */}
              <DrawerComp />
            </>
          ) : (
            <div style={{display:'flex'}}>
              {/* className="phone_button" */}
              {isAuthenticated===false&&
              <Button
                style={{
                  backgroundColor: "#1976d2",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
                variant="contained"
                onClick={() => setLoginModal(true)}
              >
                Login
              </Button>}
              {/* <Button
                style={{
                  backgroundColor: "#1976d2",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
                variant="contained"
              >
                Sign Up
              </Button> */}
              <Button
                style={{
                  backgroundColor: "#1976d2",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
                variant="contained"
              >
                <LocalPhoneRoundedIcon
                  style={{ marginRight: "5px", fontSize: "large" }}
                />{" "}
                9966966345
              </Button>
              {isAuthenticated===true&&
              <Button
                style={{
                  backgroundColor: "#1976d2",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                }}
                variant="contained"
                onClick={()=>{
                  navigate('details')
                }}
              >
                <HistoryIcon
                  style={{ marginRight: "5px", fontSize: "large" }}
                />{" "}
                Your Trips
              </Button>
}


              {/* <Dropdown
                as="li"
                className="nav-item dropdown header-profile m-0"
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img
                    src={blog2}
                    style={{ width: "40px", height: "40px", borderRadius:'25px' }}
                    alt=""
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                                     <Link to="/app-profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary me-1"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ms-2">Profile </span>
                    </Link>

                    <Link to="/details" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary me-1"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ms-2">Your Trips </span>
                    </Link>
                  
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Modal className="fade" show={loginModal} centered>
        <Modal.Header>
          <Modal.Title><h3>Login or Signup to Continue </h3></Modal.Title>
          <ClearIcon style={{ marginRight: "5px", cursor:'pointer' }}  onClick={() => setLoginModal(false)} />
        </Modal.Header>
        <Modal.Body>
          <Login updateAuthentication={handleLoginModal} updateSignUp={handleSignUp}/>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={signupModal} style={{marginTop:'40px'}}>
        <Modal.Header>
          <Modal.Title><h3>Register</h3></Modal.Title>
          <ClearIcon style={{ marginRight: "5px", cursor:'pointer' }}  onClick={() => setSignupModal(false)} />
        </Modal.Header>
        <Modal.Body>
          <div style={{maxHeight:'400px', overflowX:'auto'}}>
          <Signup/>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Header;
