import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div style={{ minHeight: "81vh" }}>
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // padding: "10px",
            gap: "10px",
            margin: "10px",
            // maxWidth:'1500px',
            padding: "40px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
          <h2>Privacy Policy</h2>
          <h4  style={{alignSelf:'flex-start'}}>1. Introduction</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            Welcome to Sri Vinayaka Travels. We are committed to protecting your
            personal information and your right to privacy. This privacy policy
            explains how we collect, use, and share information about you when
            you use our services.
          </span>

          <h4  style={{alignSelf:'flex-start'}}>2. Information We Collect</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            We may collect and process the following types of information:
          </span>
          <ul style={{alignSelf:'flex-start',listStyleType:'disc'}}>
            <li>
              <b>Personal Identification Information:</b> Name, email
              address, phone number, etc.
            </li>
            <li>
              <b>Booking Information:</b> Details related to your
              travel bookings.
            </li>
            {/* <li>
              <b>Payment Information:</b> Credit card details, billing
              address, etc.
            </li>
            <li>
              <b>Technical Information:</b> IP address, browser type,
              operating system, etc.
            </li> */}
          </ul>

          <h4  style={{alignSelf:'flex-start'}}>3. How We Use Your Information</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            We use the information we collect for various purposes, including:
          </span>
          <ul  style={{alignSelf:'flex-start',listStyleType:'disc'}}>
            <li>To process your bookings and provide travel services.</li>
            <li>
              To communicate with you about your bookings and our services.
            </li>
            <li>To improve our website and services.</li>
            <li>
              To comply with legal obligations and protect our legal rights.
            </li>
          </ul>

          <h4  style={{alignSelf:'flex-start'}}>4. How We Share Your Information</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            We do not share your personal information with third parties except
            in the following circumstances:
          </span>
          <ul  style={{alignSelf:'flex-start',listStyleType:'disc'}}>
            <li>
              With service providers who assist us in operating our business and
              providing our services.
            </li>
            <li>
              To comply with legal obligations, such as responding to subpoenas
              or other legal processes.
            </li>
            <li>
              To protect the rights and safety of our company, our customers, or
              others.
            </li>
          </ul>

          <h4  style={{alignSelf:'flex-start'}}>5. Security of Your Information</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            We take appropriate security measures to protect your personal
            information from unauthorized access, use, or disclosure.
          </span>

          <h4  style={{alignSelf:'flex-start'}}>6. Your Rights</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            You have certain rights regarding your personal information,
            including the right to access, correct, or delete your data. To
            exercise these rights, please contact us using the contact details
            provided below.
          </span>

          <h4 style={{alignSelf:'flex-start'}}>7. Changes to This Privacy Policy</h4>
          <span style={{ textAlign: "justify", lineHeight: "1.5", alignSelf:'flex-start' }}>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on our website.
            Your continued use of our services after the changes take effect
            will constitute your acceptance of the revised policy.
          </span>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default PrivacyPolicy;
