export const getEndPointUrl=(path)=>{
var url= path.replace(' ','-')
return url
}

export const camelCase=(str) =>{
    if(!str) return;
    // Using replace method with regEx
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return word.toUpperCase();
    });
}