import React from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FeedIcon from "@mui/icons-material/Feed";
import EventNoteIcon from '@mui/icons-material/EventNote';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Card, CardContent, Typography, CardMedia, Button, Box } from "@mui/material";

const DetailsCard = ({ inputData, type }) => {
  const planStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    margin: "10px",
    width: "300px",
    textAlign: "center",
    padding: '15px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "25px",
        marginBottom: "10px",
        borderWidth: "1px",
        boxShadow: "1px 1px 5px #797979",
      }}
      className="card"
    >
      <div
        // class=" section-header text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-left",
          padding: "10px",
          rowGap: "5px",
        }}
      >
        {/* <h5
          class="text-center headdingcolor"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
        {type === "packageInfo" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                // alignItems: "center",
              }}
            >
              <FeedIcon style={{ marginRight: "5px", marginTop: "3px" }} />{" "}
              <h2>Package Info</h2>
            </div>
            <span
              style={{
                alignSelf: "flex-start",
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {inputData?.packageInfo?.description}
            </span>
            {/* <ol style={{ alignSelf: "flex-start", paddingLeft: "20px", margin: 0 }}>
                {inputData?.packageInfo?.description
                  ?.split("\r\n")
                  .map((item) => (
                    <li style={{ marginBottom: "8px", color: "#555" }}>{item}</li>
                  ))}
              </ol> */}
          </div>
        )}
        {type === "itenerary" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <TextSnippetIcon style={{ marginRight: "5px" }} />
              <h2>Itenerary</h2>
            </div>
            <div style={{ display: "flex", alignSelf: "flex-start" }}>
              {inputData?.itenerary?.map((item) => (
                <div>
                  <span>{item?.stepperTitle}</span>
                  <ol
                    style={{
                      alignSelf: "flex-start",
                      paddingLeft: "20px",
                      margin: 0,
                    }}
                  >
                    {item?.description?.split("\r\n").map((item) => (
                      <li style={{ marginBottom: "8px", color: "#555" }}>
                        {item}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
          </div>
        )}
        {type === "inclusions" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <CheckIcon style={{ marginRight: "5px" }} />
              <h2>Inclusions</h2>
            </div>
            <ol
              style={{
                alignSelf: "flex-start",
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {inputData?.inclusions?.data?.split("\r\n").map((item) => (
                <li style={{ marginBottom: "8px", color: "#555" }}>{item}</li>
              ))}
            </ol>
          </div>
        )}
        {type === "exclusions" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <ClearIcon style={{ marginRight: "5px" }} />
              <h2>Exclusions</h2>
            </div>
            <ol
              style={{
                alignSelf: "flex-start",
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {inputData?.exclusions?.data?.split("\r\n").map((item) => (
                <li
                  style={{
                    alignItems: "flex-start",
                    marginBottom: "8px",
                    color: "#555",
                  }}
                >
                  {item}
                </li>
              ))}
            </ol>
          </div>
        )}

        {type === "plans" && (
     <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
     }}
   >
     <div
       style={{
         display: "flex",
         flexDirection: "row",
         gap: "5px",
         marginBottom: "10px",
         alignItems: "center",
       }}
     >
       <EventNoteIcon style={{ marginRight: "5px" }} />
       <h2>Plans</h2>
     </div>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          {inputData?.plans?.map((plan, index) => (
            <Card key={index} sx={{ ...planStyle }}>
              <CardContent>
                <Typography variant="h6">{plan.planSlug}</Typography>
                <br/>
                <Typography variant="body1">
                  <strong>For:</strong> {plan.planFor}
                </Typography>
                <Typography variant="body2">
                  <strong>Description:</strong> {plan.planDesc}
                </Typography>
                <Typography variant="body2">
                  <strong>Price:</strong> {plan.planAmount}{" "}
                  <Typography component="span" variant="caption">
                    (Original: {plan.actualAmount})
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
    )}

        {type === "subPacks" && (
          <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              marginBottom: "10px",
              // alignItems: "center",
            }}
          >
            <NewspaperIcon style={{ marginRight: "5px", marginTop: "3px" }} />{" "}
            <h2>Sub Packages</h2>
          </div>
           <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
           {inputData?.subPacks?.map((subPackage) => (
             <Card key={subPackage.sub_pid} elevation={6} style={{ maxWidth: 600, margin: "20px",borderRadius: "8px", }}>
               {/* <CardMedia
                 component="img"
                 height="140"
                 image={subPackage.sub_thumb}
                 alt={subPackage.sub_title}
               /> */}
               <CardContent>
                 <Typography variant="h5" component="div">
                   {subPackage.sub_title}
                 </Typography>
                 <Typography variant="subtitle1" color="textSecondary">
                   {subPackage.sub_subtitle}
                 </Typography>
                 <Typography variant="body2" color="textSecondary" paragraph>
                   {subPackage.sub_description}
                 </Typography>
                 <Typography variant="h6" component="div">
                   Itinerary
                 </Typography>
                 <Typography variant="body2" color="textSecondary" paragraph>
                   {subPackage.sub_itinerary.replace(/\\r\\n/g, "\n")}
                 </Typography>
                 <Typography variant="h6" component="div">
                   Inclusions
                 </Typography>
                 <Typography variant="body2" color="textSecondary" paragraph>
                   {subPackage.sub_inclusions.replace(/\\r\\n/g, "\n")}
                 </Typography>
                 <Typography variant="h6" component="div">
                   Exclusions
                 </Typography>
                 <Typography variant="body2" color="textSecondary" paragraph>
                   {subPackage.sub_exclusions.replace(/\\r\\n/g, "\n")}
                 </Typography>
                 {/* <Button variant="contained" color="primary" style={{ marginTop: "10px" }}>
                   Book Now
                 </Button> */}
               </CardContent>
             </Card>
           ))}
         </div>
         </div>
        )}

        {/* <h3>{inputData}</h3> */}
        {/* </h5> */}
        {/* <h4 style={{ alignSelf: "flex-start" }}>{inputData} Tours :</h4> */}
        {/* <ol>{getSublist(inputData?.packageInfo?.data)}</ol>
        <ol>{getSublist(inputData?.itinerary?.data)}</ol> */}
        {/* <ol>{getSublist(inputData?.inclusions?.data)}</ol> */}

        {/* <ol>{getSublist(inputData?.exclusions?.data)}</ol> */}
        {/* <ul>{inclusions}</ul>
        <ul>{exclusions}</ul> */}
        {/* <video width="320" height="240" controls>
          <source
            src="https://www.youtube.com/watch?v=Hc1zGDy81cM&t=590s"
            type="video/mp4"
          />
        </video> */}
      </div>
    </div>
  );
};

export default DetailsCard;
