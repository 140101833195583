import React from "react";
import styled from "styled-components";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  let navigate = useNavigate();
  return (
    <FooterContainer>
      {/* <span>Copyright &copy; 2021 Travelo. All rights reserved</span> */}
      <ul className="links">
      <li>
          <span style={{cursor:'pointer'}} onClick={()=>{
            navigate('about-us')
          }}>About</span>
        </li>
        <li>
          <span style={{cursor:'pointer'}} onClick={()=>{
            navigate('privacy-policy')
          }}>Privacy Policy</span>
        </li>
        <li>
          <span style={{cursor:'pointer'}} onClick={()=>{
            navigate('terms-and-conditions')
          }} >Terms and Conditions</span>
        </li>
        <li>
          <span style={{cursor:'pointer'}} onClick={()=>{
            navigate('cancellation-policy')
          }}>Cancellation Policy</span>
        </li>
      </ul>
      <ul className="social__links">
        <li>
          <BsFacebook />
        </li>
        <li>
          <AiFillInstagram />
        </li>
        <li>
          <BsLinkedin />
        </li>
      </ul>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  background-color: #d0d8ff;
  border-radius: 0.5rem;
  padding: 2.5rem;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top:auto;

  ul {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
