import React, {
  useState,
  forwardRef,
  // useImperativeHandle,
  useEffect,
} from "react";
import styled from "styled-components";
// import Destination1 from "../assets/Destination1.png";
// import Destination2 from "../assets/Destination2.png";
// import Destination3 from "../assets/Destination3.png";
// import Destination4 from "../assets/Destination4.png";
// import Destination5 from "../assets/Destination5.png";
// import Destination6 from "../assets/Destination6.png";
import noDataFound2 from "../assets/noDataFound2.png";
import noImage2 from "../assets/noImage2.png";
import info1 from "../assets/info1.png";
import info2 from "../assets/info2.png";
import info3 from "../assets/info3.png";
import { useNavigate } from "react-router-dom";
// import { getEndPointUrl } from "../Utils/Utils";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";

const Recommend = forwardRef((props, ref) => {
  const { loader } = props;
  const [packages, setPackages] = useState([]);
  // let images = [
  //   Destination4,
  //   Destination2,
  //   Destination3,
  //   Destination1,
  //   Destination5,
  //   Destination6,
  //   Destination3,
  //   Destination4,
  // ];

  useEffect(() => {
    // setActive(sessionStorage.getItem("path") ? sessionStorage.getItem("path") : packagesList[0]);
    getPackageList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPackageList = () => {
    // let listData = [];
    moduleApi.getData(apiCall.getPackagesList).then((response) => {
      // console.log(response);
      if (response?.status === 200) {
        // setPackages(response?.packages);
        // console.log(response?.packages);
        // setActive(response?.packages[0]?.linkIdentifier)

        // response?.packages?.forEach((element, index) => {
        //   let temp = {
        //     title: element?.title,
        //     subTitle: element?.subtitle,
        //     haveSubPackages: element?.haveSubPackages,
        //     totalPackages: element?.totalPackages,
        //     image: images[index],
        //     cost: element?.cost,
        //     duration:
        //       element?.numDays + " Days / " + element?.numNights + " Nights",
        //     pid: element?.pid,
        //     linkIdentifier: element.linkIdentifier,
        //   };
        //   listData.push(temp);
        // });
        setPackages(response?.packages);
        loader(false);
      }
    });
  };

  // useImperativeHandle(ref, () => ({
  //   childMethod() {
  //     let path = window.location.pathname;
  //     path = path.split("/");
  //     getPackagesDetails(path[2]);
  //   },
  // }));

  // const getPackagesDetails = (url) => {
  //   loader(true)
  //   let listData = [];
  //   moduleApi.getData(apiCall.getPackagesDetails + url).then((response) => {
  //     // console.log(response);
  //     if (response?.status === 200) {
  //       // setPackages(response?.packages);
  //       // console.log(response?.packages);
  //       // setActive(response?.packages[0]?.linkIdentifier)

  //       response?.packages?.forEach((element, index) => {
  //         let temp = {
  //           title: element?.title,
  //           subTitle: element?.subtitle,
  //           image: images[index],
  //           cost: element?.cost,
  //           duration:
  //             element?.numDays + " Days / " + element?.numNights + " Nights",
  //           pid: element?.pid,
  //           linkIdentifier: element.linkIdentifier,
  //         };
  //         listData.push(temp);
  //       });
  //       setPackages(listData);
  //       loader(false)
  //     }
  //   });
  // };

  let navigate = useNavigate();

  return (
    <Section id="recommend">
      {/* <div className="title">
        <h2>Recommended Destinations</h2>
      </div>
      <div className="packages">
        <ul>
          {packages.map((pkg, index) => {
            return (
              <li
                className={active === index + 1 ? "active" : ""}
                onClick={() => setActive(index + 1)}
              >
                {pkg}
              </li>
            );
          })}
        </ul>
      </div> */}

      {packages?.length === 0 && (
        <Section>
          <div style={{ textAlign: "center" }}>
            <img
              src={noDataFound2}
              alt="No data found"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Section>
      )}
      <div className="destinations">
        {packages?.map((destination, index) => {
          return (
            <div
            style={{cursor:'pointer'}}
              key={index}
              className="destination"
              onClick={() => {
                // console.log(destination.linkIdentifier + "/sub-package/" + destination.title);
                if (destination?.totalPackages !== "0") {
                  navigate(
                    "/tour-packages/package/" +
                      destination.linkIdentifier +
                      "/" +
                      destination?.pid
                  );
                } else {
                  // navigate(
                  //   "/tour-packages/details/" + destination.linkIdentifier
                  // );
                  navigate(
                    "/tour-packages/details/" +
                      destination.linkIdentifier +
                      "/" +
                      destination?.pid
                  );
                }
                localStorage.setItem("packageId", destination?.pid);
              }}
            >
              <img
                src={destination.thumb || noImage2} // Initial source
                alt={destination.title || "No data available"}
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop in case `noImage` also fails
                  e.target.src = noImage2; // Fallback image if the original fails
                }}
                style={{ maxWidth: "100%", height: "auto" }}
              />

              <h3>{destination.title}</h3>
              <p>{destination.subTitle}</p>
              <div className="info">
                <div className="services">
                  <img src={info1} alt="" />
                  <img src={info2} alt="" />
                  <img src={info3} alt="" />
                </div>
                <h4>{destination.cost}</h4>
              </div>
              <div className="distance">
                {/* <span>1000 Kms</span> */}
                <span>
                  {destination?.numDays +
                    " Days / " +
                    destination?.numNights +
                    " Nights"}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
});

export default Recommend;

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-bottom: 15px;
    padding: 0 3rem;
    align-items: center;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #8338ec14;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
