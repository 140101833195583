import React, { useEffect, useState } from "react";
import { camelCase } from "../Utils/Utils";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
// import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { useNavigate } from "react-router-dom";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import DetailsCard from "./DetailsCard";
// import { Grid } from "@mui/material";

const PackageDetails = () => {
  const [path, setPath] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    setPath(path[3]);
    console.log(path);
    var body = {
      subPackageID: path[4],
    };

    // let formData = new FormData();
    // formData.append("subPackageID", path[4]);
    loadPackageDetails(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getYoutubeEmbedUrl(url) {
    // Function to extract the video ID from the YouTube URL
    const extractVideoId = (youtubeUrl) => {
      const urlParams = new URLSearchParams(youtubeUrl.split("?")[1]);
      return urlParams.get("v");
    };
    // Extract video ID from provided URL
    const videoId = extractVideoId(url);
    // Check if video ID is valid
    if (!videoId) {
      return null; // Return null for invalid URLs
    }
    // Construct the embed URL using the extracted video ID
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }

  const loadPackageDetails = (body) => {
    moduleApi.postData(apiCall.getSubPackageDetails, body).then((response) => {
      if (response?.status === 200) {
        console.log(response);
        setPackageDetails(response.data);
        // let itineraryObj ={
        //   name: "itinerary",
        //   data: getItinerary(response.itinerary)
        // }
        // setItinerary({
        //   name: "itinerary",
        //   data: getItinerary(response.itinerary)
        // });
      }
    });
  };

  // function getItinerary(data){
  //   return listItems = data.map(item =>
  //     <li>{item}</li>
  //   );
  // }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundColor: "#443bb7",
          color: "white",
          position: "sticky",
          padding: "20px",
          marginTop: "-40px",
          width: "100%",
        }}
      >
        {camelCase(path?.replaceAll("-", " "))}
      </div>
      <Container>
        <div className="row">
        {/* style={{ display: "flex", flexDirection: "row" }} */}
          {/* <Grid container spacing={2} >
        <Grid item xs={9}> */}
          <div >
          {/* className=" col-lg-9 pdetails" */}
            {" "}
            {/* <div
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                marginBottom: "10px",
                // borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 5px #797979",
              }}
              className="card"
            >
              <header
                class=" section-header text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  rowGap: "5px",
                }}
              >
                <h5
                  class="text-center headdingcolor"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <LocalOfferRoundedIcon style={{ marginRight: "5px" }} />{" "}
                  <h3 style={{ marginBottom: "5px" }}>Ramoji Film City Tour</h3>
                </h5>

                <span class="line text-center"></span>
                <span
                  style={{
                    color: "lightgreen",
                    fontWeight: "600",
                    textAlign: "justify",
                    lineHeight: "1.5",
                  }}
                >
                  Sri Vinayaka Travels is an 'Authorized Business Associate' for
                  Ramoji Film City online bookings and we provide 100% Confirmed
                  Ramoji Film City Entry Tickets at same cost. Sri Vinayaka
                  Travels is the one and only website that provides online
                  booking for both Ramoji Film City Bus Transportation and Entry
                  Tickets in single ticket booking.
                </span>
                <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
                  Ramoji Film City is the world of wonders, surprises and gives
                  you a magical experience. The Film studio complex is located
                  near Hayathnagar, Hyderabad. Ramoji Film City is the large
                  integrated film studio constructed in almost 2000 acres of
                  land in 1996 and is certified by ‘Guinness World Records’ as
                  the ‘World’s Largest Studio Complex’ and the studio is
                  extremely popular as the Tourism place and recreation center.
                  Every year it draws more than 1.5 million tourists and
                  visitors and creates an astonishing experience for them.{" "}
                </span>
                <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
                  Ramoji Film City is the dreamland created by Mr. Sri Ramoji
                  Rao, the Telugu Film producer who always want to build a
                  studio similar to the Universal Studios in Hollywood. It took
                  years to complete the studio, and he finally created the live
                  studio of his blueprint with lots of surprises for children,
                  Holiday Carnivals, Theme sets, parks, and amusements.
                </span>
                <h5 style={{ alignSelf: "flex-start" }}>
                  Ramoji Film City Entry Ticket Price:
                </h5>
                <h4 style={{ alignSelf: "flex-start" }}>
                  1. Normal Studio Tour Package Prices:
                </h4>
                <ol
                  style={{
                    listStyleType: "lower-roman",
                    alignSelf: "flex-start",
                    marginLeft: "15px",
                    textAlign: "justify",
                    lineHeight: "1.5",
                  }}
                >
                  <li>
                    Adult (above 55 inches height) Price: 1350 + 18% GST =
                    Rs.1593
                  </li>
                  <li>
                    Child (between 33 to 54 inches height) Price: 1150 + 18% GST
                    = Rs.1357
                  </li>
                </ol>
                <h4 style={{ alignSelf: "flex-start" }}>
                  2. Star Experience Package Prices:
                </h4>
                <ol
                  style={{
                    listStyleType: "lower-roman",
                    alignSelf: "flex-start",
                    marginLeft: "15px",
                    textAlign: "justify",
                    lineHeight: "1.5",
                  }}
                >
                  <li>
                    Adult (above 55 inches height) Price: 2999 + 18% GST =
                    Rs.3539
                  </li>
                  <li>
                    Child (between 33 to 54 inches height) Price: 2799 + 18% GST
                    = Rs.3303
                  </li>
                </ol>
                <h4 style={{ alignSelf: "flex-start" }}>
                  3. Normal Studio Tour with Veg-Buffet Lunch Package Prices:
                </h4>
                <ol
                  style={{
                    listStyleType: "lower-roman",
                    alignSelf: "flex-start",
                    marginLeft: "15px",
                    textAlign: "justify",
                    lineHeight: "1.5",
                  }}
                >
                  <li>
                    Adult (above 55 inches height) Price: 1730 + 18% GST =
                    Rs.2042
                  </li>
                  <li>
                    Child (between 33 to 54 inches height) Price: 1445 + 18% GST
                    = Rs.1705
                  </li>
                </ol>
                <h4 style={{ alignSelf: "flex-start" }}>Important Notes:</h4>
                <ol
                  style={{
                    listStyleType: "lower-roman",
                    alignSelf: "flex-start",
                    marginLeft: "15px",
                    textAlign: "justify",
                    lineHeight: "1.7",
                  }}
                >
                  <li>
                    Ramojij Film City Child Entry Ticket prices are depends on
                    the child height not age. Less than 32 inches, Entry Ticket
                    not required, 33 to 54 inches Child prices applicable, above
                    55 inches Adult prices applicable.
                  </li>
                  <li>
                    Any Ramoji Film City Entry Package does not include Bus
                    Transportation(Pick-up & Drop-off service). A/C Bus
                    Transportation starts from Rs. 475 and Non-A/C Bus
                    Transportation starts from Rs. 410
                  </li>
                  <li>
                    If you have your own vehicle for transportation, you can
                    Skip Our Bus Transportation(Book Only Entry Tickets) and go
                    with your vehicle. At Ramoji Film City Gate, Parking
                    Facility (Paid) is available. So you can park your vehicle
                    and enjoy the tour and come back by your vehicle.
                  </li>
                  <li>
                    Your own vehicles will not be allowed inside Ramoji Film
                    City. They only provide vehicles for roaming inside for
                    sightseeing. For Normal Packages (Non-A/C Bus) and for Star
                    package A/C Bus will be provided.
                  </li>
                </ol>
                <h4 style={{ alignSelf: "flex-start", color: "red" }}>
                  Is Sri Vinayaka Travels safe for Ramoji Film City Entry
                  Tickets Booking?
                </h4>
                <ol
                  style={{
                    listStyleType: "lower-roman",
                    alignSelf: "flex-start",
                    marginLeft: "15px",
                    textAlign: "justify",
                    lineHeight: "1.7",
                  }}
                >
                  <li>
                    Yes 100% safe. Sri Vinayaka Travels is an Authorized
                    Business Associate for Ramoji Film City, you will get 100%
                    genuine entry tickets.
                  </li>
                  <li>
                    You will have wide range of Payment options like
                    Debit/Credit Card, Internet Banking, Mobile Wallets, QR-Code
                    Scan & pay and more.
                  </li>
                  <li>
                    Online Transactions in bestbus are 100% Safe & Secure and
                    your transaction details were encrypted
                  </li>
                  <li>
                    Entry Tickets price is same for online and direct bookings.
                  </li>
                  <li>
                    Ramoji Film City Hotel Rooms booking facility also available
                    with us.
                  </li>
                </ol>
                <h3 style={{ color: "green" }}>
                  Hurry Up... Limited Period Offer...!
                </h3>
                <Button
                  style={{
                    // marginRight: "10px",
                    // marginLeft: "7px",
                    color: "white",
                    marginTop: "10px",
                    fontSize: "medium",
                    fontWeight: "600",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => navigate("/form")}
                >
                  Book Now
                </Button>
              </header>
            </div> */}
            {packageDetails?.packageInfo && (
              <DetailsCard inputData={packageDetails} type="packageInfo" />
            )}
            {packageDetails?.itenerary?.length > 0 && (
              <DetailsCard inputData={packageDetails} type="itenerary" />
            )}
            {packageDetails?.inclusions && (
              <DetailsCard inputData={packageDetails} type="inclusions" />
            )}
            {packageDetails?.exclusions && (
              <DetailsCard inputData={packageDetails} type="exclusions" />
            )}
            {packageDetails?.plans?.length > 0 && (
              <DetailsCard inputData={packageDetails} type="plans" />
            )}
            {packageDetails?.subPacks?.length > 0 && (
              <DetailsCard inputData={packageDetails} type="subPacks" />
            )}
            <div style={{display:'flex', justifyContent:"center", alignItems:'center',margin: "10px"}}>
            {packageDetails?.videos &&
              packageDetails?.videos?.map((item, index) => (
                <iframe
                  style={{ borderRadius: "20px", margin: "10px" }}
                  width="400"
                  height="315"
                  src={getYoutubeEmbedUrl(item?.videoLink)}
                  title={'video'+ index}
                  frameborder="0"
                  allowFullScreen
                ></iframe>
              ))}
              </div>
          </div>
          {/* </Grid>
          <Grid item xs={3}> */}
          <div className="card"
          // className="card col-lg-3 cdetails"
            style={{
              backgroundColor: "#fff",
              // maxWidth: "25%",
              padding: "10px",
              // borderRadius: "3px",
              maxHeight: "11rem",
              borderWidth: "1px",
              boxShadow: "1px 1px 5px #797979",
            }}
          >
            <div style={{ boxSizing: "borderBox", marginTop: "0 !important" }}>
              <header
                class=" section-header text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  rowGap: "5px",
                }}
              >
                <h5
                  class="text-center headdingcolor"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <QuestionAnswerRoundedIcon
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Have any Queries?
                </h5>

                <span class="line text-center"></span>
                <p
                  class=" text-center black carsfont"
                  style={{ fontWeight: "600" }}
                >
                  Do not hesitate to contact us{" "}
                </p>
                <h3
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    color: "green",
                    cursor: "pointer",
                  }}
                >
                  <LocalPhoneRoundedIcon style={{ marginRight: "5px" }} />{" "}
                  9966966345
                </h3>
                <Button
                  style={{
                    // marginRight: "10px",
                    // marginLeft: "7px",
                    color: "white",
                    marginTop: "10px",
                    fontSize: "medium",
                    fontWeight: "600",
                    fontFamily:
                      " -apple-system,BlinkMacSystemFont,poppins,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
                  }}
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => navigate("/form")}
                >
                  Book Now
                </Button>
              </header>
            </div>
          </div>
          {/* </Grid>
          </Grid> */}
        </div>
      </Container>
    </div>
  );
};

export default PackageDetails;

const Container = styled.footer`
  display: flex;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  padding: 3rem;

  @media (min-width: 992px) {
    pdetails {
      flex: 0 0 70%;
      max-width: 70%;
      overflow-wrap: anywhere;
    }
  }

  @media (min-width: 992px) {
    cdetails {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  ul {
    display: flex;
    list-style-type: none;
    gap: 1rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
