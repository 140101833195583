import React, { useState, useEffect } from "react";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { Badge } from "react-bootstrap";

const BookingDetails = () => {
  const [bookingDetails, setBookingDetails] = useState([]);

  useEffect(() => {
    const body = { userId: "1" };
    getBookingDetails(body);
  }, []);

  const getBookingDetails = (body) => {
    moduleApi.postData(apiCall.getPastBookings, body).then((response) => {
      if (response?.status === 200) {
        setBookingDetails(response.data);
      }
    });
  };

  return (
    <div>
      <p style={styles.sectionTitle}>Your Trips</p>
      <div style={styles.cardsContainer}>
        {bookingDetails.map((booking, index) => (
          <div key={index} style={{ ...styles.card }} className="card">
            <strong style={styles.bookingTitle}>
              {booking.bName} - {booking.dname}
            </strong>

            <div style={styles.container}>
              <div style={styles.section}>
                <p style={styles.text}>
                  Booking ID:<strong> {booking.booking_id}</strong>
                </p>
                <p style={styles.text}>
                  Name:<strong> {booking.name}</strong>
                </p>
                <p style={styles.text}>
                  Phone:<strong> {booking.phone}</strong>
                </p>
                <p style={styles.text}>
                  Title:<strong> {booking.package.title}</strong>
                </p>
              </div>
              <div style={styles.section}>
                <p style={styles.text}>
                  Package Name:<strong> {booking.package.subtitle}</strong>
                </p>
                <p style={styles.text}>
                  Sub package Name:
                  <strong> {booking.subPackageDetails.sub_subtitle}</strong>
                </p>
                <p style={styles.text}>
                  Status:
                  {booking.subPackageDetails.sub_subtitle === "Completed" && (
                    <Badge bg="success">Completed</Badge>
                  )}
                  {booking.subPackageDetails.sub_subtitle === "Cancel" && (
                    <Badge bg="danger">Cancel</Badge>
                  )}
                  {booking.subPackageDetails.sub_subtitle === "Inprogress" && (
                    <Badge bg="warning" text="dark">
                      In Progress
                    </Badge>
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  title: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  card: {
    padding: "25px",
    marginBottom: "10px",
    borderWidth: "1px",
    borderRadius: "10px",
    boxShadow: "1px 1px 10px #aaa",
    width: "100%",
    maxWidth: "900px",
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    border: "1px solid #ddd",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  section: {
    flex: 1,
    padding: "10px",
  },
  sectionTitle: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  bookingTitle: {
    fontSize: "22px",
    marginBottom: "10px",
    color: "green",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    marginBottom: "8px",
  },
};

export default BookingDetails;
