import React from "react";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

const AboutUs = () => {
  return (
    <div>
      <div style={{ minHeight: "81vh" }}>
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // padding: "10px",
            gap: "10px",
            margin: "10px",
            // maxWidth:'1500px',
            padding: "40px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
          <h1>About Us</h1>
          <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
            Sri Vinayaka Travels is a well-known company in the bus industry,
            with a goal to revolutionize the industry. Since our establishment,
            we have placed a strong emphasis on passenger comfort and have
            continually added luxurious buses to our extensive fleet. Our main
            focus is to ensure that our passengers’ comfort is never
            compromised. We constantly strive to improve and enhance the travel
            experience for our customers. Learn more about what sets us apart in
            the market.
          </span>
          <h3 style={{ alignSelf: "flex-start" }}>Our Mission</h3>
          <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
            Proactively understand customer needs and expectations and fulfil
            them, by continuously challenging ourselves to provide innovative
            solutions to the travelling sector.
          </span>
          <h3 style={{ alignSelf: "flex-start" }}>Our Vision</h3>
          <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
            Sri Vinayaka Travels is committed to provide consistently high
            quality of services and to continuously improve the services through
            a process of teamwork for the utmost satisfaction of the passengers
            and to attain a position of pre-eminence in the bus transport
            sector. By serving beyond customer expectations for every journey
            whilst adding value to all our customers and society at large.{" "}
          </span>
          <h3 style={{ alignSelf: "flex-start" }}>Our Philosophy</h3>
          <span style={{ textAlign: "justify", lineHeight: "1.5" }}>
            Sri Vinayaka Travels believes in building relationship with
            customers, having different visions and experience, who contribute
            to managing the growth, direction and success of the company.
            <ul style={{ listStyleType: "disc" }}>
              <li>Understand Customer needs</li>
              <li>Earn customer trust and confidence</li>
              <li>Achieve customer goal expected from us</li>
            </ul>
            It is the intention of Sri Vinayaka Travels to grow hand-in-hand
            with our customers, encouraging, cheering and aiding them to reach
            their maximum potential and improve their standard of living.
          </span>
        </div>
        <div
          className="card"
          // className="card col-lg-3 cdetails"
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // maxWidth: "25%",
            padding: "10px",
            margin: "10px",
            // borderRadius: "3px",
            maxHeight: "11rem",
            borderWidth: "1px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
          <div
            style={{
              boxSizing: "borderBox",
              marginTop: "0 !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Contact Us</h2>
            <h5
              class="text-center headdingcolor"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <QuestionAnswerRoundedIcon style={{ marginRight: "5px" }} />{" "}
              <span>
                If you have any questions or concerns about this privacy policy
                or our data practices, please contact us at:
              </span>
            </h5>

            <span class="line text-center"></span>
            {/* <span
                class=" text-center black carsfont"
                style={{ fontWeight: "600" }}
              >
                Do not hesitate to contact us{" "}
              </span> */}
            <span>
              Sri Vinayaka Travels
              <br />
              Hyderabad
              <br />
              srivinyakatravela@gmail.com
              <br />
            </span>
            <h3
              style={{
                marginTop: "10px",
               
                color: "green",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LocalPhoneRoundedIcon style={{ marginRight: "5px" }} />{" "}
              9966966345
            </h3>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default AboutUs;
