import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div style={{ minHeight: "81vh" }}>
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // padding: "10px",
            gap: "10px",
            margin: "10px",
            // maxWidth:'1500px',
            padding: "40px",
            boxShadow: "1px 1px 5px #797979",
          }}
        >
          <h2>Terms and Conditions</h2>
          <ul style={{ listStyleType: "auto", lineHeight: "1.7" }}>
            <li>
             
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              offers travellers to book Ramoji Film City Entry
              Tickets, Telangana Tourism Packages Booking, Other Local City
              Sightseeing Tour Packages online right from the comfort of their
              home or office by providing them with choice of bus operators,
              departure times and prices.{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              does not own or operate any bus services on its own.{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              does not advise any specific bus operator to its customers. The
              choice of the bus operator is purely the discretion of the
              customer.
            </li>
            <li>
              The arrival and departure times mentioned on the ticket are only
              tentative timings. Buses may be delayed due to some unavoidable
              reasons like traffic jams etc. However, the bus will not leave the
              source before the time that is mentioned on the ticket. Passengers
              are advised to call the bus operator contact number mentioned in
              the ticket and reconfirm the boarding point location and departure
              time on the day of journey. If the passenger did not get any
              contact number of the bus operator, he/she need to call the{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              Customer Support after booking the ticket and should be at least
              3-4 hours prior to their reporting time.
            </li>
            <li>
              Passengers are required to furnish the following details at the
              time of boarding the bus. Failing to do so, they may not be
              allowed to board the bus.
              <ul>
                <li>
                  SMS of the ticket or Email. (This is widely accepted by most
                  bus operators)
                </li>
                <li>
                  Some operators do not accept the electronic ticket formats. We
                  provide the details of such operators while booking tickets on{" "}
                  <span
                    style={{
                      textAlign: "justify",
                      lineHeight: "1.5",
                      alignSelf: "flex-start",
                    }}
                  >
                    Sri Vinayaka Travels
                  </span>
                  . In such case we request you to kindly carry a printout of
                  the ticket.
                </li>
                <li>
                  Proof of Identity (Driving License or Aadhar Card or Passport
                  or PAN Card or Voter Card)
                </li>
              </ul>
            </li>
            <li>
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              Ultimate and Assured classification of Bus Operators is done from
              time-to-time based on Customer’s Feedback and Surveys conducted by{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>
              .{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              is showing the classification for customers to make an informed
              decision while choosing a Bus Operator for their journey. The
              choice of the bus operator is purely at the discretion of the
              customer. The classification of Bus Operator might change
              depending on the service performance of bus operator.
            </li>
            <li>
              The passengers should reach at the mentioned boarding points 15
              minutes prior to the scheduled departure time.
            </li>
            <li>
              Any complaints/grievances should be reported in a week’s time from
              date of journey performed.
            </li>
            <li>
              A booking is considered confirmed if the booking confirmation page
              is shown to the customer, even if SMS/Email fails to deliver for
              any reason.
            </li>
            <li>
              If ticket confirmation/cancellation, SMS/Email, is not sent for
              any reason, if reported it can be resent by our support team.
              SMS/Email might fail to deliver or get delayed for several reasons
              which are not in{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>
              's control.
            </li>
            <li>
              Payment gateway/service charges –{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              will not charge any additional payment gateway charge or service
              unless otherwise specified in the booking details page.
            </li>
            <li>
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              provides a platform to Insurance company to list and advertise
              their services on the website and App and allows customers You
              (“Customer/You”) to select Insurance Policy, based on your
              preferences. The listing of an Insurance Policy on the website
              does not constitute and should not be regarded as a
              recommendation. Once a reservation is made, and Insurance policy
              is selected You are agreeing to the terms and conditions of the
              Insurance Company.
            </li>
            <li>
              By making a booking on{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              – customer acknowledges that{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              will Call/Email/SMS or send alerts to give/take information
              regarding his/her bookings.
            </li>
            <li>
              In case of any claims arising out of unforeseen
              consequences/exigencies,{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              liability would be limited to the extent of the value of the
              booking amount.
            </li>
            <li>
              The terms and conditions of our services can be amended, modified
              or withdrawn by{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              at any time without notice.
            </li>
            <li>
              All legal disputes are subject to exclusive jurisdiction of the
              competent courts in Hyderabad (Telangana) only.
            </li>
            <li>
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              is not responsible if any Wrong Origin-Destination combination
              booked by the passenger. In that cases,{" "}
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              can not cancel the ticket. It is based on the cancellation policy.
            </li>
            <li>
              <span
                style={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  alignSelf: "flex-start",
                }}
              >
                Sri Vinayaka Travels
              </span>{" "}
              is not responsible if any Origin City Name or Destination City
              Name exists in two different states and the passenger booked
              another state city instead of their desired city. Passenger has to
              verify the details and should have basic knowledge on their route
              which they want to travel.
            </li>
            <li>
              <b>
                By submitting our webform, you agree to receive promotional
                calls on the number shared, and such calls and SMS would be
                coming from a third-party platform.
              </b>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default TermsAndConditions;
