import React from "react";
// import Home from "./Home";
import Recommend from "./Recommend";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import * as moduleApi from "../rest/moduleApi";
// import { apiCall } from "../rest/restApi";

const useTabStyles = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

const Temp = (props) => {
  const {loader}= props;
  // eslint-disable-next-line
  // const [path, setPath] = useState(window.location.pathname);
  // const [packageDetails, setPackageDetails] = useState(null);
  // const childRef = useRef();

  // const receiveDataFromChild = (data) => {
  //   // setPath(data);
  //   let path = window.location.pathname;
  //   // eslint-disable-next-line
  //   path = path.split("/");
  //   // if (packageDetails == undefined || path[2] == data) {
  //   // getPackagesDetails(data);
  //   childRef.current.childMethod();
  //   // }
  // };

  
  // useEffect(() => {
  //   // setPath(sessionStorage.getItem("path"));
  //   let path = window.location.pathname;
  //   path = path.split("/");
  //   // getPackagesDetails(path[2]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getPackagesDetails = async (url) => {
  //   console.log("Fetching package details for URL:", url);
  //   try {
  //     const response = await moduleApi.getData(apiCall.getPackagesDetails + url);
  //     console.log("API response:", response);
  //     if (response?.status === 200) {
  //       console.log("Fetched package details:", response.packages);
  //       setPackageDetails(response.packages);
  //     } else {
  //       console.error("Failed to fetch package details: Unexpected response status", response?.status);
  //       setPackageDetails(null);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch package details:", error);
  //     setPackageDetails(null);
  //   }
  // };
  

  const classes = useTabStyles();

  let packagesList = ['Tour Packages', 'Wondela', 'Ramoji Film City', 'Yadadri', 'TTD']

  return (
    <div>
      {/* <Home sendDataToParent={receiveDataFromChild} /> */}
      <Tabs
        classes={{ root: classes.root, scroller: classes.scroller }}
        value={packagesList[0]}
        // onClick={sendData(active)}
        // onChange={(event, newValue) => {
        //   setActive(newValue);
        //   navigate("/package/" + newValue);
        //   // sessionStorage.setItem("path", newValue);
        //   sendData(newValue);
        // }}
        indicatorColor="primary"
        textColor="primary"
        variant={"scrollable"}
        scrollButtons={"on"}
      >
        {packagesList.map((element, index) => (
          <Tab key={index} label={element} value={element} />
        ))}
      </Tabs>
        <div style={{minHeight:'80vh'}}>
          {/* <p>{packageDetails?.length >0 && packageDetails[0]?.message}</p> */}
          {/* <Recommend ref={childRef} loader={loader} /> */}
          <Recommend loader= {loader}/>
        </div>
        <div style={{flex:1}}></div>
    </div>
  );
};

export default Temp;
