export const apiCall = {
  getPackagesList: "getPackages",
  getPackagesDetails: "getPackageInfoBySlug/",
  getSubPackages: "getSubPackages/",
  getSubPackageDetails: "getSubPackageDetails",
  getLoginOtp:"login",
  verifyOtp:"verifyPhoneNumber",
  getBoardingPoints:"getBoardingPoints",
  getDroppingPoints:"getDroppingPoints",
  getPastBookings:"getPastBookings",
  saveUserTripDetails:"saveUserTripDetails",
  verifyPayment:"verifyPayment",
  signup:"signup"
};
