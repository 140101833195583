import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Action.css";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { toaster } from "../rest/apiUtils";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.number()
    .required("Age is required")
    .positive("Age must be positive")
    .integer("Age must be an integer"),
  profilepic: Yup.mixed().required("Profile pic is required")
});

const Signup = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  const [userId, setUserId] = useState(0);
  

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFieldValue("profilepic", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit =(values) => {
    console.log(values);
    moduleApi.postData(apiCall.signup, values).then((response) => {
      console.log(response);
      // if (response.status === 200){
      //     setIsOtpVerification(true);
      //   return toaster("success", "OTP Sent Successfully");
      // }
      if (
        response?.message === "OTP sent to entered phone number, please verify"
      ) {
        setUserId(response.data);
        setIsOtpVerification(true);
        return toaster("success", "OTP Sent Successfully");
      }
      // else if(response?.message === "User not exist navigate to signup"){
      //   updateSignUp();
      // }
    });
  };
  

  return (
    <div className="form-container">
      <Formik
        initialValues={{
          name: "",
          mobileNumber: "",
          email: "",
          gender: "",
          age: "",
          profilepic: null
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          handleSubmit(values);
        }}
      >
        {({ setFieldValue, errors, touched }) => (
          <Form>
            <div className="file-upload">
              <div className="image-preview-container">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Profile Preview"
                    className="image-preview"
                  />
                ) : (
                  <div className="image-placeholder">No Image</div>
                )}
              </div>
              <input
                type="file"
                name="profilepic"
                id="profilepic"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, setFieldValue)}
              />
              <label htmlFor="profilepic">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  
                >
                  Upload Image
                </Button>
              </label>
              <FormHelperText style={{ color: "red" }}>
                <ErrorMessage name="profilepic" />
              </FormHelperText>
            </div>
            <Field
              as={TextField}
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="name" />}
              error={touched.name && Boolean(errors.name)}
            />
            <Field
              as={TextField}
              name="mobileNumber"
              label="Mobile Number"
              variant="outlined"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="mobileNumber" />}
              error={touched.mobileNumber && Boolean(errors.mobileNumber)}
            />
            <Field
              as={TextField}
              name="email"
              label="Email ID"
              variant="outlined"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="email" />}
              error={touched.email && Boolean(errors.email)}
            />
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={touched.gender && Boolean(errors.gender)}
            >
              <InputLabel>Gender</InputLabel>
              <Field as={Select} name="gender" label="Gender">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Field>
              <FormHelperText>
                <ErrorMessage name="gender" />
              </FormHelperText>
            </FormControl>
            <Field
              as={TextField}
              name="age"
              label="Age"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage style={{ color: "red" }} name="age" />}
              error={touched.age && Boolean(errors.age)}
            />
            
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Signup;
